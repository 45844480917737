import { Observable, of } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { OutRealEstateDTO, WithdrawalProductType } from '@generated/generatedEntities';
import { environment } from '@env/environment';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { RealEstateActions } from '@app/core/services/store/real-estate.actions';
import { RebuildEstateDataService } from '@app/core/services/rebuild/rebuild-estate-data/rebuild-estate-data.service';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class RealEstateRepository {
  private http = inject(HttpClient);
  private store = inject(Store);
  private rebuildEstateDataService = inject(RebuildEstateDataService);
  // realEstate$: Observable<RebuildEstateDTO>;

  // private store;

  getEmpty(
    withdrawalProduct: WithdrawalProductType,
    tasks: WithdrawalProductType[] = [],
  ): Observable<OutRealEstateDTO> {
    setTimeout(() => {
      this.store.dispatch(RealEstateActions.getEmptyRealEstate({ wpt: withdrawalProduct, tasks }));
    }, 0);
    return of({} as OutRealEstateDTO);
  }

  get(id: number) {
    return this.http.get<OutRealEstateDTO>(`${environment.serverUrl}/api/realestates/${id}`).pipe(
      tap({
        next: (entity) => {
          try {
            this.rebuildEstateDataService.processEditRebuildRealEstate(entity);
            if (entity.energy != null && entity.energy.id != null) {
              entity.energy.id = null;
            }
            if (entity.energy != null && entity.energy.current != null && entity.energy.current.id != null) {
              entity.energy.current.id = null;
            }
            if (entity.energy != null && entity.energy.target != null && entity.energy.target.id != null) {
              entity.energy.target.id = null;
            }
          } catch (e) {
            console.error(e);
          }
          this.store.dispatch(RealEstateActions.updateRealEstate(_.cloneDeep(entity)));
        },
      }),
      map((value) => ({}) as OutRealEstateDTO),
    );
  }
}
